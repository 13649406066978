<template>
  <contentCard titleText="每日签到">
    <div slot="right-handle" class="my-integral-text">
      我的积分：<span class="value-text">{{ integralInfo.availableTokenPoint }}</span>
    </div>
    <div class="lxqd-text">
      已连续签到 <span class="value-text">{{ signInfo.continuousSignInCount }}</span> 天
    </div>
    <div class="sign-score-list">
      <div
        class="sign-score-item"
        :class="{ active: index + 1 <= signInfo.continuousSignInCount }"
        v-for="(item, index) in signScoreList"
        :key="'qdjf' + index"
      >
        <div class="value-text">+{{ item }}</div>
      </div>
    </div>
    <div class="btn" v-if="signInfo.todayIsSignIn === true">已签到</div>
    <div class="btn" v-else @click="ljqdOnClick">立即签到</div>

    <van-dialog v-model="signTipDiaShow" title="提示" message-align="left" theme="round-button" confirm-button-color="#1874FD" width="600">
      <div class="tip-content">
        <img class="success-icon" src="./img/success_bg.png">
        <div class="success-text">签到成功</div>
        <div class="value-text">获得 {{ jfbhText }} 积分</div>
      </div>
    </van-dialog>
  </contentCard>
</template>

<script>
import contentCard from '../components/contentCard.vue';
import { Dialog, Icon } from 'vant';
import { mapState } from 'vuex';
import { getSignInDetailUrl, getIntegralDetailUrl, handSignInUrl } from './api.js';

export default {
  name: 'DailySign',
  components: {
    contentCard,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon
  },
  props: {},
  model: {},
  data() {
    return {
      integralInfo: {},
      signScoreList: [],
      signInfo: {},
      signTipDiaShow: false,
      jfbhText: ''
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  watch: {},
  created() {
    // this.$axios.get(`/gateway/hc-mini/user/mini/user-info`, { params: { userId: this.userId } }).then(res => {
    //   console.log('1开始');
    //   Object.keys(res.data).forEach(k => {
    //     console.log('1.' + k + '=>' + res.data[k]);
    //   })
    //   console.log('1结束');
    // })
    // this.$axios.get(`/gateway/hc-mini/user/aio/getMyUserInfo`, { params: { userId: this.userId } }).then(res => {
    //   console.log('2开始');
    //   Object.keys(res.data).forEach(k => {
    //     console.log('2.' + k + '=>' + res.data[k]);
    //   })
    //   console.log('2结束');
    // });
    this.getJfInfo();
    this.getQdInfo();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getJfInfo() {
      this.$axios
        .post(getIntegralDetailUrl, this.$qs.stringify({ userId: this.userId, tenantId: this.tenantId }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            this.integralInfo = res.data;
          }
        });
    },
    getQdInfo() {
      this.$axios
        .post(getSignInDetailUrl, this.$qs.stringify({ userId: this.userId, tenant: this.tenantId }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.signData = res.data.signData || {};
            res.data.signData.scoreList = res.data.signData.scoreList || '';
            this.signInfo = res.data;
            this.signScoreList = res.data.signData.scoreList.split('');
          }
        });
    },
    ljqdOnClick() {
      this.$axios
        .post(
          handSignInUrl,
          this.$qs.stringify({
            userId: this.userId,
            tenant: this.tenantId,
            communityId: this.communityId,
            roomId: this.houseId
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then(res => {
          if (res && res.code === 200) {
            this.signTipDiaShow = true;
            this.getJfInfo();
            this.getQdInfo();
            res.data = res.data || {};
            this.jfbhText = (res.data.operationType === 1 ? '+' : '-') + res.data.tokenPoint;
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 50px 30px 50px;
}
.my-integral-text {
  font-size: 24px;
  color: #2f3a4f;
  font-weight: 700;
  line-height: 40px;
  .value-text {
    box-sizing: border-box;
    font-size: 33px;
    font-weight: 700;
  }
}
.lxqd-text {
  box-sizing: border-box;
  color: #2f3a4f;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  .value-text {
    box-sizing: border-box;
    font-size: 34px;
    color: #1874fd;
  }
}
.sign-score-list {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 120px);
  margin: 15px 0px;
  .sign-score-item {
    box-sizing: border-box;
    width: calc((100% / 7) - 20px);
    height: 100%;
    border-radius: 8px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    .value-text {
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      max-width: 100%;
      border-radius: 50%;
      background-color: #ebebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #2f3a4f;
    }
    &.active {
      background-color: #1874fd;
      .value-text {
        background-color: #69a2f6;
        color: #ffffff;
      }
    }
  }
}
.btn {
  box-sizing: border-box;
  height: 40px;
  width: 240px;
  border-radius: 6px;
  background-color: #1874fd;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tip-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success-icon {
    box-sizing: border-box;
    width: 42px;
    margin: 10px 0px;
  }
  .success-text {
    box-sizing: border-box;
    color: #2F3A4F;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }
  .value-text {
    box-sizing: border-box;
    color: #2F3A4FCC;
    font-size: 16px;
    margin: 10px 0px;
  }
}
</style>
