// 查询 - 签到详情
const getSignInDetailUrl = `/gateway/hc-accumulated/dailyPerf/getSignInStatus`;

// 查询 - 积分详情
const getIntegralDetailUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;

// 操作 - 签到
const handSignInUrl = `/gateway/hc-accumulated/dailyPerf/doSignIn`;

export { getSignInDetailUrl, getIntegralDetailUrl, handSignInUrl };
